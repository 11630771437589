import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectEmpleado (Vue, idempleado) {
    const apiFilter = new APIFilter()
      .addExact('idempleado', idempleado)
    const resp = await Vue.$api.call('empleado.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idempleado, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idempleado, TABLA.empleado.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
}
