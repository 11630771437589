<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './UsuarioViewData.js'
import { get } from 'vuex-pathify'
import { TABLA } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    tipo: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        changePassword: {
          title: 'Cambiar contraseña',
          visible: false,
          icon: 'password'
        },
      }
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioIdempleado: get('usuario/idempleado'),
    formattedItem() {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idempleado
        item.title = item.nombre_y_apellido
        item.subtitle = item.codigo
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Cuenta de usuario'
      await this.loadItem()
      this.toolbarOptions.changePassword.visible = this.hasViewPerm(this.permissions.sesion.cambiarContrasena)
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectEmpleado(
          this,
          this.usuarioIdempleado
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals() {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.usuarioIdempleado,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // ficheros
      if (this.hasViewPerm(this.permissions.empleadoAdjunto.id)) {
        const detailFicheros = this.addDetail(
          "adjuntos", "Adjuntos", "Fotografías y ficheros adjuntos", "attach"
        )
        detailFicheros.badge =
          resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
    },
    clickDetail(data) {
      if (data.detail.name === "adjuntos") {
        this.$appRouter.push({
          name: "ficheros__empleados-fichero-list",
          params: {
            id: this.usuarioIdempleado,
            idtabla: TABLA.empleado.idtabla,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.changePassword) {
        this.$appRouter.push({
          name: "configuracion__usuario-edit",
        })
      }
    },
  },
}
</script>
